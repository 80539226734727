<template>
  <student-form
    :is-visible="isVisible"
    @update-triggered="triggerUpdateStudent()"
    title="Data Inti"
  >
    <h1 class="text-xl text-center uppercase tracking-wider">DATA PRIBADI</h1>

    <form action="#" @submit.prevent="triggerUpdateStudent()">
      <!-- Student Name -->
      <label class="form-label" for="student_name">Nama</label>
      <input disabled type="text" id="student_name" v-model="form.name" />
      <p class="text-sm text-gray-800">
        * Jika ada kekeliruan nama, mohon segera menghubungi petugas
      </p>

      <!-- Student Gender -->
      <span class="form-label">Jenis kelamin</span>
      <!-- Male -->
      <input
        type="radio"
        id="student_male"
        name="gender"
        value="1"
        v-model="form.gender"
      />
      <label class="ml-2 mr-6" for="student_male">Laki-laki</label>
      <!-- Female -->
      <input
        type="radio"
        id="student_female"
        name="gender"
        value="0"
        v-model="form.gender"
      />
      <label class="ml-2" for="student_female">Perempuan</label>

      <!-- NISN -->
      <label class="form-label mt-4" for="student_nisn">NISN</label>
      <p class="text-sm text-gray-800">
        * (Opsional) Nomor Induk Siswa Nasional, jika ada
      </p>
      <input type="text" id="student_nisn" v-model="form.nisn" />

      <!-- KK -->
      <label class="form-label" for="student_kk">No. Kartu Kependudukan (KK)</label>
      <input
        type="text"
        placeholder="No. KK"
        id="student_kk"
        v-model="form.kk"
      />

      <!-- NIK -->
      <label class="form-label" for="student_nik">NIK</label>
      <p class="text-sm text-gray-800">
        * Nomor Induk Kependudukan yang dimiliki calon siswa, terdapat dalam
        Kartu Keluarga (KK)
      </p>
      <input
        type="text"
        placeholder="16 digit"
        id="student_nik"
        v-model="form.nik"
      />

      <!-- Birth Place -->
      <label class="form-label" for="student_bp">tempat lahir</label>
      <input type="text" id="student_bp" v-model="form.birth_place" />

      <!-- Birth date -->
      <label class="form-label" for="student_bd">tanggal lahir</label>
      <datetime
        id="student_bd"
        title="Tanggal Lahir"
        placeholder="Tanggal Lahir"
        class="custom-datepicker"
        :use12-hour="false"
        v-model="form.birth_date"
        :auto="true"
      ></datetime>
      <!-- <input type="text" id="student_bd" v-model="form.birth_date" /> -->

      <!-- Sibling positoin -->
      <label class="form-label" for="student_sp">anak ke</label>
      <input type="text" id="student_sp" v-model="form.sibling_position" />

      <!-- Sibling total -->
      <label class="form-label" for="student_st">jumlah saudara kandung</label>
      <input type="text" id="student_st" v-model="form.sibling_total" />

      <!-- previous school -->
      <label class="form-label" for="student_previous_school">
        sekolah sebelumnya (TK / SD)
      </label>
      <p class="text-sm text-gray-800">
        * (Opsional) Gunakan nama SD Jika pindahan dari sekolah lain
      </p>
      <input
        type="text"
        id="student_previous_school"
        v-model="form.previous_school"
      />

      <!-- previous school ID -->
      <label class="form-label" for="student_previous_school_id"> NPSN TK   </label>
      <p class="text-sm text-gray-800">
        * (Opsional) Tuliskan angka 0 (nol) jika tidak melalui jenjang TK
      </p>
      <input
        type="text"
        id="student_previous_school_id"
        v-model="form.npsn_tk"
      />

      <span class="mt-8 mb-4 block tracking-wider uppercase font-bold"
        >Keadaan Jasmani</span
      >
      <!-- Serious Ilness -->
      <label class="form-label" for="student_si">Penyakit Berat</label>
      <p class="text-sm text-gray-800">* (Opsional) Jika ada</p>
      <input type="text" id="student_si" v-model="form.serious_illness" />

      <!-- Dietary -->
      <label class="form-label" for="student_dr">Pantangan makanan</label>
      <p class="text-sm text-gray-800">* (Opsional) Jika ada</p>
      <input type="text" id="student_dr" v-model="form.dietary_restriction" />

      <!-- height -->
      <label class="form-label" for="student_he">Tinggi Badan (cm)</label>
      <p class="text-sm text-gray-800">
        * Cukup menuliskan angka, misal 120 untuk menyatakan tinggi siswa adalah
        120 cm
      </p>
      <input
        type="text"
        id="student_he"
        placeholder="Hanya angka, misal 120"
        v-model="form.height"
      />

      <!-- Weight -->
      <label class="form-label" for="student_we">Berat Badan (KG)</label>
      <p class="text-sm text-gray-800">
        * Cukup menuliskan angka, misal 16 untuk menyatakan berat siswa adalah
        16 kg
      </p>
      <input
        type="text"
        id="student_we"
        placeholder="Hanya angka, misal 16"
        v-model="form.weight"
      />

      <!-- Hobby -->
      <label class="form-label" for="student_hobby">Hobi</label>
      <p class="text-sm text-gray-800">
        * maks. 100 karakter/huruf
      </p>
      <input
        type="text"
        id="student_hobby"
        placeholder="maks. 100 karakter/huruf"
        v-model="form.hobby"
      />

      <!-- Aspiration -->
      <label class="form-label" for="student_aspiration">Cita-cita</label>
      <p class="text-sm text-gray-800">
        * maks. 100 karakter/huruf
      </p>
      <input
        type="text"
        id="student_aspiration"
        placeholder="maks. 100 karakter/huruf"
        v-model="form.aspiration"
      />


    </form>
    <template #goto-next-form-trigger>
      <button
        class="button --primary text-white uppercase tracking-wider"
        @click="triggerUpdateStudent('address')"
      >
        <span class="mr-2">Formulir Berikutnya</span>
        <fa-icon icon="arrow-right"></fa-icon>
      </button>
    </template>
  </student-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";
import StudentForm from "@/components/Registration/StudentForm.vue";
import _clone from "lodash/clone";

export default {
  data() {
    return {
      isProcessing: false,
      form: {},
      formDefault: {},
    };
  },
  methods: {
    async triggerUpdateStudent(next = null) {
      this.isProcessing = true;

      await this.updateStudent({ item: this.form, next: next });
      this.isProcessing = false;
    },

    ...mapMutations("student", ["HIDE_FORM", "SHOW_FORM"]),
    ...mapActions("student", ["updateStudent"]),
  },
  watch: {
    selected_form(val) {
      if (val === "core") {
        this.form = _clone(this.selected_student);
        this.form.address = [];
        this.form.parents = [];
      } else {
        this.form = _clone(this.formDefault);
      }
    },
  },
  computed: {
    isVisible() {
      return this.form_visibility === true && this.selected_form === "core";
    },

    ...mapGetters("student", [
      "selected_form",
      "form_visibility",
      "selected_student",
    ]),
  },
  mounted() {
    this.formDefault = _clone(this.form);
    Settings.defaultLocale = "id";
  },
  components: { Datetime, StudentForm },
  name: "CoreForm",
};
</script>